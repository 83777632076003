import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { fetchRewardPoints, applyRewardPoints, cancelRewardPoints } from '../../actions/rewardAction';
import { Row, Col, Label, Button, Input } from 'reactstrap'

const RewardPoints = () => {
    const dispatch = useDispatch();

    const authMember = useSelector(state => state.auth.authMember)
    const rewardPointsState = useSelector((state) => state.reward || {});


    const [usePoint, setUsePoint] = useState('')

    useEffect(() => {
        if (authMember) {
            dispatch(fetchRewardPoints(authMember.id));
        }
    }, [authMember, dispatch]);

    const handleUsePointChange = (event) => {
        setUsePoint(event.target.value);
    };

    const handleApplyPoints = () => {
        if (parseInt(usePoint) <= rewardPointsState.rewardPoints) {
           
            dispatch(applyRewardPoints(authMember.id, usePoint, rewardPointsState.redemption?.id))
                .then((response) => {
                    alert("Points applied successfully!");
                    dispatch(fetchRewardPoints(authMember.id));
                })
                .catch((error) => {
                    alert(`Error: ${error.message}`);
                    setUsePoint('');
                });
        } else {
            alert("Insufficient points available!");
        }
    };

    const handleCancelPoints = () => {
        if (authMember && rewardPointsState?.redemption) {
            dispatch(cancelRewardPoints(authMember.id, rewardPointsState.redemption.id))
                .then(() => {
                    alert('Points canceled successfully')
                    dispatch(fetchRewardPoints(authMember.id));
                })
                .catch((error) => alert(`Error: ${error.message}`));
        }
        setUsePoint('');
    };

    return (rewardPointsState.rewardPoints || rewardPointsState.redemption) ?
        (
            <Row>
                <Col lg="12" md="12" sm="12">
                    <b>Use Reward Points</b>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <p>
                        You have <b> { rewardPointsState?.rewardPoints ?? 0 } points </b> available
                    </p>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <p className="mb-2">Enter amount of points to apply</p>
                    <Input
                        type="number"
                        className="form-control mb-2"
                        value={usePoint}
                        onChange={handleUsePointChange}
                        placeholder="Enter points"
                        min="5"
                        max={rewardPointsState.rewardPoints || 0}
                    />
                </Col>
                <Col lg="12" md="12" sm="12">
                    <div className="d-flex flex-column flex-md-row gap-2">
                        <Button style={{ flexGrow: 1 }} onClick={handleApplyPoints}>Apply Points</Button>
                        <Button 
                            style={{ flexGrow: 1 }} 
                            onClick={handleCancelPoints} 
                            disabled={!rewardPointsState?.redemption}
                        >
                            Cancel Points
                        </Button>
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                        <div className="d-flex flex-column flex-md-row align-items-md-start align-items-lg-center mt-md-2">
                        <Label htmlFor='usedRewardPoints' className="form-label pr-2" style={{ paddingRight: '5px' }}>Applied Reward Points</Label>
                        <div style={{ flexGrow: 1 }}>
                            <Label>
                                <b>{rewardPointsState.redemption?.points_redeemed || 0} Reward Points</b>
                            </Label>
                        </div>
                    </div>
                </Col>
                <Col lg="12" md="12" sm="12">
                    <hr/>
                </Col>
            </Row>
        ) : '';
};

export default RewardPoints;
