import React from 'react'
import { Row, Col, Badge } from 'reactstrap'
import { SERVER_URL } from '../../config/constants/common.js'

const BookingRow = (props) => {
    const { image, vehicle_name, price, booking_no, start_date, end_date, collection_time, return_time } = props.item
    const imgUrl = `${SERVER_URL}${image}`

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-CA');
    };

    return <Row style={{ marginBottom: '15px', paddingBottom: '15px', borderBottom: '1px solid #ddd' }}>
            <Col lg="2" md="2" sm="12" className="mt-2">
                <img src={imgUrl} style={{ width: '100%', height: 'auto' }}/>
            </Col>
            <Col lg="4" md="4" sm="12" className="mt-2">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                    <h5> {vehicle_name} </h5>
                    <p style={{ marginBottom: '0px' }}>${ price } / day</p>
                    <p>Booking No: { booking_no }</p>
                </div>
            </Col>
            <Col lg="3" md="3" sm="12" className="mt-2">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                    <p style={{ marginBottom: '0px' }}>Start Date: { formatDate(start_date) }</p>
                    <p style={{ marginBottom: '0px' }}>End Date: { formatDate(end_date) }</p>
                </div>
            </Col>
            <Col lg="3" md="3" sm="12" className="mt-2">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                    <p style={{ marginBottom: '0px' }}>Collection Time: { collection_time }</p>
                    <p style={{ marginBottom: '0px' }}>Return Time: { return_time }</p>
                </div>
            </Col>
        </Row>
}

export default BookingRow