import { API_URL } from '../config/constants/common.js';
import { memberAuthHeader } from '../utils/authHeaders.js';
import {
    rewardPointsPending,
    rewardPointsSuccess,
    rewardPointsFailure,
    applyPointsPending,
    applyPointsSuccess,
    applyPointsFailure,
    cancelPointsPending,
    cancelPointsSuccess,
    cancelPointsFailure,
    resetRedemption,
} from '../reducers/rewardPointsSlice';

export const fetchRewardPoints = (member_id) => async (dispatch) => {
    dispatch(rewardPointsPending());

    return fetch(`${API_URL}reward-points/${member_id}`, {
        method: 'GET',
        headers: memberAuthHeader(),
    })
        .then((res) => res.json())
        .then((result) => {
            if (result.code == 200) {
                dispatch(rewardPointsSuccess(result.data));
                return Promise.resolve(true);
            } else if (result.code == 400) {
                dispatch(rewardPointsFailure('Bad Request'));
                return Promise.reject(result);
            } else {
                dispatch(rewardPointsFailure(result.error));
                throw result.error;
            }
        })
        .catch((error) => {
            dispatch(rewardPointsFailure(error.message));
            return Promise.reject(error);
        });
};

export const applyRewardPoints = (memberId, points, redemptionId) => async (dispatch) => {
    try {
        dispatch(applyPointsPending());

        const formData = new FormData()
        formData.append('user_id', memberId)
        formData.append('points_redeemed', points)
        if (redemptionId) {
            formData.append('redemption_id', redemptionId)
        }

        const response = await fetch(`${API_URL}redemptions/redeem`, {
            method: 'POST',
            headers: memberAuthHeader(),
            body: formData
        });

        const result = await response.json();
        if (result.code === 200) {
            dispatch(applyPointsSuccess(result.data));
            return Promise.resolve(result);
        } else {
            dispatch(applyPointsFailure(result.error || "An error occurred"));
            if (result.code == 400) {
                return Promise.reject(new Error(result.messages.points_redeemed[0]));
            } else {
                return Promise.reject(new Error(result.error));
            }
        }
    } catch (error) {
        dispatch(applyPointsFailure(error.message));
        return Promise.reject(error);
    }
};

export const cancelRewardPoints = (userId, redemptionId) => async (dispatch) => {
    try {
        dispatch(cancelPointsPending());

        const formData = new FormData()
        formData.append('redemption_id', redemptionId)

        const response = await fetch(`${API_URL}redemptions/cancel`, {
            method: 'POST',
            headers: memberAuthHeader(),
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `Error canceling reward points`);
        }

        const data = await response.json();

        dispatch(cancelPointsSuccess(data));
    
        dispatch(resetRedemption());
    } catch (error) {
        dispatch(cancelPointsFailure(error.message));
        return Promise.reject(error);
    }
};

