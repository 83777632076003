import { createSlice } from '@reduxjs/toolkit'

const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: {
        data: [],
        details: {},
        busyVehicleDays: [],
        unavailableVehicleDays: [],
        vehicleNames: [],
        loading: false,
        error: null,
        currentPage: 1,
        totalPages: 1,
    },
    reducers: {
        vehicleListPending: (state) => {
            state.loading = true
            state.error = null
        },
        vehicleListSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.currentPage = action.payload.current_page;
            state.totalPages = action.payload.last_page;
        },
        vehicleListFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        vehiclePending: (state) => {
            state.loading = true
            state.error = null
        },
        vehicleSuccess: (state, action) => {
            state.loading = false;
            state.details = action.payload;
        },
        vehicleFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        unavailableVehicleDaysPending: (state) => {
            state.loading = true
            state.error = null
        },
        unavailableVehicleDaysSuccess: (state, action) => {
            state.loading = false
            state.unavailableVehicleDays = action.payload
        },
        unavailableVehicleDaysFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        busyVehicleDaysSuccess: (state, action) => {
            state.loading = false
            state.busyVehicleDays = action.payload
        },
        vehicleNamesPending: (state) => {
            state.loading = true
            state.error = null
        },
        vehicleNamesSuccess: (state, action) => {
            state.loading = false
            state.vehicleNames = action.payload
        },
        vehicleNamesFailure: (state, action) => {
            state.loading = false 
            state.error = action.payload
        }
    }
})

export const { 
    vehicleListPending, 
    vehicleListSuccess, 
    vehicleListFailure,
    vehiclePending,
    vehicleSuccess,
    vehicleFailure,
    unavailableVehicleDaysPending,
    unavailableVehicleDaysSuccess,
    unavailableVehicleDaysFailure,
    busyVehicleDaysSuccess,
    vehicleNamesPending,
    vehicleNamesSuccess,
    vehicleNamesFailure
} = vehicleSlice.actions

export default vehicleSlice.reducer