import { createSlice } from '@reduxjs/toolkit'

const rewardPointsSlice = createSlice({
    name: 'rewardPoints',
    initialState: {
        loading: false,
        error: null,
        rewardPoints: 0,
        redemption: null,
        cancelRewardPoints: null,
    },
    reducers: {
        rewardPointsPending: (state) => {
            state.loading = true;
            state.error = null;
        },
        rewardPointsSuccess: (state, action) => {
            state.loading = false;
            state.rewardPoints = action.payload;
        },
        rewardPointsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        applyPointsPending: (state) => {
            state.loading = true;
            state.error = null;
        },
        applyPointsSuccess: (state, action) => {
            state.loading = false;
            state.redemption = action.payload
        },
        applyPointsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        cancelPointsPending: (state) => {
            state.loading = true;
            state.error = null;
        },
        cancelPointsSuccess: (state, action) => {
            state.loading = false;
            state.cancelRewardPoints = action.payload;
        },
        cancelPointsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetRedemption: (state) => {
            state.redemption = null;
        },
    }
})

export const {
    rewardPointsPending,
    rewardPointsSuccess,
    rewardPointsFailure,
    applyPointsPending,
    applyPointsSuccess,
    applyPointsFailure,
    cancelPointsPending,
    cancelPointsSuccess,
    cancelPointsFailure,
    resetRedemption,
} = rewardPointsSlice.actions;

export default rewardPointsSlice.reducer;