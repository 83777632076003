import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import './../../styles/WhatsAppButton.css'

const WhatsAppButton = () => {
  return (
    <a
      href="https://api.whatsapp.com/send/?phone=%2B6588768853&text=I'm%20interested%20in%20your%20car&app_absent=0"
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <BsWhatsapp className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;
