// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
  }
  
  .whatsapp-icon {
    margin-top: 15px; /* Adjust this to center the icon vertically */
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/WhatsAppButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,4BAA4B;IAC5B,aAAa;EACf;;EAEA;IACE,gBAAgB,EAAE,8CAA8C;EAClE","sourcesContent":[".whatsapp_float {\n    position: fixed;\n    width: 60px;\n    height: 60px;\n    bottom: 20px;\n    right: 20px;\n    background-color: #25d366;\n    color: white;\n    border-radius: 50px;\n    text-align: center;\n    font-size: 30px;\n    box-shadow: 2px 2px 3px #999;\n    z-index: 1000;\n  }\n  \n  .whatsapp-icon {\n    margin-top: 15px; /* Adjust this to center the icon vertically */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
